<template>
  <el-row class="pd-24 bx-bd">
    <el-row class="mb-20">
      <div class="pad-container flx-row ali-c">
        <el-input
          placeholder="请输入房间名"
          class="search-input"
          v-model.trim="searchObj.roomName"
        ></el-input>
        <el-select
          placeholder="直播状态"
          class="search-input ml-15"
          v-model="searchObj.roomStatus"
        >
          <el-option
            v-for="(item, index) in playStatusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          placeholder="按门店分组"
          class="search-input ml-15"
          v-model="searchObj.storeId"
        >
          <el-option
            v-for="(item, index) in storeOptions"
            :key="index"
            :label="item.storeName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          placeholder="是否上架"
          class="search-input ml-15"
          v-model="searchObj.status"
        >
          <el-option
            v-for="(item, index) in bannerOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          placeholder="是否轮播"
          class="search-input ml-15"
          v-model="searchObj.isBanner"
        >
          <el-option
            v-for="(item, index) in statusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          class="ml-15"
          @click="search"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
          >重置</el-button
        >
      </div>
    </el-row>
    <div class="pad-container">
      <el-table :data="tableData">
        <el-table-column label="房间号列表" prop="roomId"></el-table-column>
        <el-table-column label="直播主题" prop="name"></el-table-column>
        <el-table-column label="直播时间" prop="startTime"></el-table-column>
        <el-table-column label="直播状态">
          <template slot-scope="scope">{{
            liveStatusFilter(scope.row.liveStatus)
          }}</template>
        </el-table-column>
        <el-table-column label="主图/背景图/商品图片">
          <template slot-scope="scope">
            <el-button @click="handleShow(scope.row)" type="text"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="主播" prop="anchorName"></el-table-column>
        <el-table-column label="门店">
          <template slot-scope="scope">{{
            storeIdFilter(scope.row.storeId)
          }}</template>
        </el-table-column>
        <el-table-column label="上架">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="上架"
              inactive-text="下架"
              @change="(val) => toggle(scope.row.roomId, val)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否进入轮播" prop="isBanner">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.isBanner"
              :true-label="1"
              :false-label="0"
              @change="(val) => change(scope.row.roomId, val)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :pageNum="pageNum"
        :pageSize="pageSize"
        :pageSizes="pageSizes"
        @syncPageData="syncPageData"
      />
    </div>
    <el-dialog :visible="visible" @close="cancel" title="查看">
      <h3>直播主图</h3>
      <el-avatar shape="square" :size="100" :src="row.coverImg"></el-avatar>
      <h3>直播商品图</h3>
      <el-table :data="goods_tableData">
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <el-avatar
              shape="square"
              :size="50"
              :src="scope.row.coverImg"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="name"></el-table-column>
        <el-table-column label="商品价格" prop="price">
          <template slot-scope="scope">￥{{ scope.row.price/100 }}</template>
        </el-table-column>
        <el-table-column label="链接地址" prop="url"></el-table-column>
      </el-table>
      <Pagination
        :total="d_total"
        :pageNum="d_pageNum"
        :pageSize="d_pageSize"
        @syncPageData="d_syncPageData"
        :hideSizes="true"
        :type="'dialog'"
      />
      <div class="flx-row ali-c js-fe mt-50">
        <el-button type="primary" @click="cancel">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible="visible2" title="编辑" width="450px" @close="cancel2">
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item prop="storeId" label="门店选择">
          <el-select
            v-model="form.storeId"
            class="form-input-300"
            placeholder="请选择门店"
          >
            <el-option
              v-for="(item, index) in storeOptions"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button @click="cancel2">取消</el-button>
          <el-button type="primary" @click="submit('form')">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </el-row>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  operationStatus,
  operationBanner,
  getStoreList,
  editRoom,
  roomList
} from "@/api/live";
export default {
  components: { Pagination },
  data() {
    return {
      form: {
        storeId: "",
        name: "",
        picPath: "",
      },
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      total: 1,
      playStatusOptions: [
        {
          label: "直播中",
          value: 101,
        },
        {
          label: "未开始",
          value: 102,
        },
        {
          label: "已结束",
          value: 103,
        },
        {
          label: "禁播",
          value: 104,
        },
        {
          label: "暂停",
          value: 105,
        },
        {
          label: "异常",
          value: 106,
        },
        {
          label: "已过期",
          value: 107,
        },
      ],
      bannerOptions: [
        {
          label: "上架",
          value: 1,
        },
        {
          label: "未上架",
          value: 0,
        },
      ],
      statusOptions: [
        {
          label: "轮播",
          value: "1",
        },
        {
          label: "未轮播",
          value: "0",
        },
      ],
      storeOptions: [],
      visible: false,
      row: {},
      d_total: 0,
      d_pageNum: 1,
      d_pageSize: 5,
      visible2: false,
      rules: {
        storeId: [
          {
            required: true,
            message: "门店不能为空",
            trigger: "blur",
          },
        ],
      },
      searchObj: {
        isBanner: "",
        status: "",
        roomName: "",
        roomStatus: "",
        storeId: "",
      },
      allData: [],
      goods_tableData: [],
      allGoodsData: [],
      mainImgStr: "",
    };
  },
  mounted() {
    this.getData();
    this.getStoreList();
  },
  methods: {
    // 上下架
    toggle(roomId, val) {
      const data = {
        roomId,
        status: val,
      };
      console.log(data)
      operationStatus(data).then((res) => {
        this.$message.success("操作成功");
      });
    },
    // 进出轮播
    change(roomId, val) {
      const data = {
        roomId,
        isBanner: val ? '1' :'0',
      };
      console.log(data)
      operationBanner(data).then((res) => {
        this.$message.success("操作成功");
      });
    },
    // 过滤状态
    storeIdFilter(val) {
      let label = "";
      this.storeOptions.map((item) => {
        if (item.id == val) {
          label = item.storeName;
        }
      });
      return label;
    },
    // 获取门店名
    liveStatusFilter(val) {
      let label = "";
      this.playStatusOptions.map((item) => {
        if (item.value == val) {
          label = item.label;
        }
      });
      return label;
    },
    //获取门店列表
    getStoreList() {
      getStoreList().then((res) => {
        this.storeOptions = res.data.body.list;
      });
    },
    submit(form) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      const data = {
        roomId: this.row.roomId,
        storeId: this.form.storeId,
        roomName: this.row.name,
      };
      editRoom(data).then((res) => {
        this.$message.success("操作成功");
        this.cancel2();
        this.getData();
      });
    },
    handleEdit(row) {
      this.row = row;
      this.form.storeId = row.storeId;
      this.visible2 = true;
    },
    cancel() {
      this.visible = false;
    },
    cancel2() {
      this.visible2 = false;
      this.$refs.form.resetFields();
    },
    handleShow(row) {
      console.log(row)
      this.row = row;
      this.allGoodsData = row.goods;
      this.d_total = row.goods.length;
      this.getGoods();
      this.visible = true;
    },

    search() {
      this.pageNum = 1;
      this.getData();
    },
    reset() {
      Object.keys(this.searchObj).map((item) => {
        this.searchObj[item] = "";
      });
      this.search();
    },
    getData() {
      let data = this.searchObj;
      roomList(data).then((res) => {
        this.total = res.data.body.length;
        this.allData = res.data.body;
        this.getPageGoods();
      });
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.getPageGoods();
    },
    getPageGoods() {
      this.tableData = this.allData.slice(
        (this.pageNum - 1) * this.pageSize,
        (this.pageNum - 1) * this.pageSize + this.pageSize
      );
    },
    getGoods() {
     
      this.goods_tableData = this.allGoodsData.slice(
        (this.d_pageNum - 1) * this.d_pageSize,
        (this.d_pageNum - 1) * this.d_pageSize + this.d_pageSize
      );
       console.log(this.goods_tableData)
    },
    d_syncPageData(data) {
      this.d_pageNum = data.num;
      this.d_pageSize = data.size;
      this.getGoods();
    },
    getImgData(imgData, type) {
      // console.log(imgData);
      if (type == "main") this.form.picPath = imgData.mainImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.img-wrapper >>> .el-upload-list--picture-card li {
  border-radius: 50%;
}
</style>